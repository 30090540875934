
import axios from 'axios';
export default {
    async AIGenerateText(context, format){

		console.log('Generating content... format:', format);

		try{
			const serverData = await axios.get(`/api/clients/findAIServer/${context.getters.getAuthUsername}`);
			console.log('serverIP', serverData);
			const serverIP = serverData.data;
            //serverIP
			if(serverIP){
				let paramsObj;
				if(format === 'title' || format === 'details'){
					const parentNodeId = context.getters.getCurrentNode.parent;
					const parentNode = await axios.get(`/api/clients/getCurrentNode/${parentNodeId}`);
					paramsObj = {
					  fields: JSON.stringify(context.getters.getAIPopUpFields),
					  format: format,
					  node: JSON.stringify(parentNode.data),
					  clientSlug: context.getters.getClientSlug,
					  serverIP: serverIP
					}; 
					console.log('ai params:', params);
				}
				if(format === 'blog' || format === 'social'){
			
					paramsObj = {
						fields: JSON.stringify(context.getters.getAIPopUpFields),
						format: format,
						clientSlug: context.getters.getClientSlug,
						serverIP: serverIP
					}; 
			
				}
		
                let host = window.location.host; // "localhost:8080"
                let hostname = host.split(':')[0]; // "localhost"

                console.log('sending ai data:', [paramsObj, `${window.location.protocol}//${hostname}/ai/generativeAI`]);

                let params = { param1: 'value1', param2: 'value2' };
                const response = await axios.post(`${window.location.protocol}//${hostname}/ai/generativeAI`, { ...paramsObj });
                const data = response.data;
                context.commit('setAIPopUpFields', {prop: 'generatedContent', val: data});
				console.log('AI RESPONSED DATA:', data);
                const res = await axios.get(`/api/clients/removeAIServer/${context.getters.getAuthUsername}`);
                console.log('removed server:', res.data);
			}else{
				alert('Sorry, AI slots are currently all being used. Please try again later.');
			}
		}catch(error){
			console.log('AIGenerateText catch error', error);
            try {
                const res = await axios.get(`/api/clients/removeAIServer/${context.getters.getAuthUsername}`);
                console.log('removed server:', res.data);
                alert('Sorry, there has been a problem. Please try again later.');
                context.commit('setAIPopUpFields', {prop: 'generatedContent', val: 'Unable to get response'});
            } catch (error) {
                console.log('Error removing server:', error);
            }
		}
	},
    approveAIGeneratedContent(context, payload){
        const status = payload.status
        const format = payload.format
        if(status){
            const data = context.getters.getAIPopUpFields.generatedContent;
            console.log('the approved data:', data);
            
            if(format === 'social'){
                context.commit('setSocial', {prop: 'post/caption', val: data});
            }
            if(format === 'blog'){
                context.commit('setAIPopUpFields', {prop: 'response', val: data})
            }
            if(format === 'title'){
                context.commit("updateNodeFieldValue", {fieldGroup : 'title', field: 'title', value : data})
            }
            if(format === 'details'){
                context.commit("updateNodeFieldValue", {fieldGroup : 'details', field: 'details', value : data})
            }
        }else{
            context.commit('setAIPopUpFields', {prop: 'generatedContent', val: ''});
            console.log('user has denied')
        }
    }
}